<template>
  <van-goods-action>
    <van-goods-action-icon :icon="collected? 'star':'star-o'" :text="collected? '已收藏':'未收藏'" color="darkred" @click="collected = !collected" />
    <van-goods-action-icon icon="cart-o" text="购物车" badge="0" />
    <van-goods-action-button type="danger" text="立即购买" @click="buyNow" />
    <van-goods-action-button type="warning" text="加入购物车" />
  </van-goods-action>
</template>

<script>
import activityApi from '@/api/activity'
export default {
  props: {
    activityId: {
      type: Number,
      require: true,
      default: -1
    }
  },
  data() {
    return {
      collected: false
    }
  },
  methods: {
    buyNow() {
      console.log(this.activityId)
      orderApi.create({ activityId: this.activityId }).then(res => {
        if (res.succ) {
          this.$router.push({ path: `/orderConfirm/${res.data.item}` })
        }
      })
    }
  }
}
</script>

<style>
</style>
