<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
      <van-swipe-item>1</van-swipe-item>
      <van-swipe-item>2</van-swipe-item>
      <van-swipe-item>3</van-swipe-item>
      <van-swipe-item>4</van-swipe-item>
    </van-swipe>

    <div class="title">
      <span>常见问题</span>
    </div>
    <div class="issue">
      <ul>
        <li v-for="item in 4" :key="item">
          <h3>问题一</h3>
          <p>问题一的解答问题一的解答问题一的解答问题一的解答问题一的解答问题一的解答问题一的解答问题一的解答</p>
        </li>
      </ul>
    </div>
    <MyGoodsAction />
  </div>
</template>

<script>
import MyGoodsAction from './components/MyGoodsAction'
export default {
  components: {
    MyGoodsAction
  },
  data() {
    return {}
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      console.log(this.$route.params.id)
    }
  }
}
</script>

<style lang="less" scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 250px;
  text-align: center;
  background-color: #39a9ed;
}
/deep/ .box {
  width: 100%;
  display: block;
}
.title {
  background-color: #fff;
  width: 100%;
  height: 50px;
  position: relative;
  &::before {
    content: '';
    width: 50%;
    height: 2px;
    background-color: #ccc;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -25%;
  }
  span {
    width: 30%;
    position: relative;
    background-color: #fff;
    display: block;
    text-align: center;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
  }
}
.issue {
  padding: 2px 4%;
  background-color: #fff;
  li {
    h3 {
      font-size: 16px;
      height: 20px;
      line-height: 20px;
      position: relative;
      padding-left: 14px;
      font-weight: normal;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        display: inline-block;
        top: 50%;
        margin-top: -2px;
        left: 0;
        background-color: darkred;
      }
    }
    p {
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: #ccc;
      padding-left: 20px;
    }
  }
}
</style>
